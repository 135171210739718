





















































































import { Component, Vue } from 'vue-property-decorator';
import LinkStrategyModerateComparisonPesosAndModerateViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-goal/link-strategy-moderate-comparison-pesos-and-moderate-view-model';

@Component({ name: 'LinkStrategyModerateComparisonPesosAndModerate' })
export default class LinkStrategyModerateComparisonPesosAndModerate extends Vue {
  strategy_comparison_pesos_and_moderate_view_model = Vue.observable(
    new LinkStrategyModerateComparisonPesosAndModerateViewModel(this),
  );

  created() {
    this.strategy_comparison_pesos_and_moderate_view_model.initialize();
  }
}
