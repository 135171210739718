import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type StrategyBenefitTranslation = {
  label: string;
  value: string;
}

type StrategyTranslation = {
  title: string;
  strategy_name: string;
  strategy_description_1: string;
  strategy_description_2: string;
  strategy_description_3: string;
  benefits: Array<StrategyBenefitTranslation>;
}

export default class LinkStrategyModerateComparisonPesosAndModerateViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.link-sws-moderate.link-strategy-moderate-comparison-pesos-and-moderate';

  strategies: Array<StrategyTranslation> = [];

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  loadStrategies = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.strategies = this.translate('strategies');
  }

  getClassNameByStrategy = (strategy_name: string) => (strategy_name === 'SWS - Pesos' ? 'primary--text' : 'white--text primary');

  getClassNameByBenefit = (value: string) => (value.includes('BBB') ? 'text-small' : 'text-body');

  initialize = () => {
    this.view.$emit('setDynamicWidth', '60');
    this.loadStrategies();
  }
}
